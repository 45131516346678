<!-- @format -->

<template>
	<div>
		<b-tag type="is-info" size="is-medium">Customer's Invoice Term</b-tag>
		<span class="text-bold margin-left-20">{{ term }}</span>
		<b-field class="margin-top-20" label="Select invoice date">
			<b-datepicker
				v-model="invoiceDate"
				:show-week-number="showWeekNumber"
				:locale="locale"
				placeholder="Click to select..."
				icon="calendar-today"
				trap-focus
				:first-day-of-week="firstDayOfTheWeek"
			>
			</b-datepicker>
		</b-field>
		<b-field label="Select invoice due date">
			<b-datepicker
				v-model="invoiceDueDate"
				:show-week-number="showWeekNumber"
				:locale="locale"
				:min-date="invoiceDate"
				placeholder="Click to select..."
				icon="calendar-today"
				trap-focus
				:first-day-of-week="firstDayOfTheWeek"
			>
			</b-datepicker>
		</b-field>
		<b-dropdown v-model="isSwedish" aria-role="list">
			<template v-if="isSwedish" #trigger>
				<b-button
					label="Swedish"
					type="is-info"
					icon-left="home"
					icon-right="menu-down"
				/>
			</template>

			<template v-else #trigger>
				<b-button
					label="English"
					type="is-primary"
					icon-left="earth"
					icon-right="menu-down"
				/>
			</template>

			<b-dropdown-item :value="true" aria-role="listitem">
				<div class="media">
					<b-icon class="media-left" icon="home"></b-icon>
					<div class="media-content">
						<h3>Swedish</h3>
						<small>Invoice Language will be Swedish</small>
					</div>
				</div>
			</b-dropdown-item>

			<b-dropdown-item :value="false" aria-role="listitem">
				<div class="media">
					<b-icon class="media-left" icon="earth"></b-icon>
					<div class="media-content">
						<h3>English</h3>
						<small>Invoice Language will be English</small>
					</div>
				</div>
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
import { onMounted, ref, watch, computed } from '@vue/composition-api'
import addDays from 'add-days'
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'

export default {
	name: 'InvoiceHeader',
	setup() {
		const showWeekNumber = ref(false)
		const firstDayOfTheWeek = ref(1)
		const locale = 'sv-SE'
		const invoiceDate = ref(new Date())
		const invoiceDueDate = ref(new Date())
		const getDueDate = (date) => {
			let rawInvoiceDueDate = addDays(
				date,
				stateSalesInvoice.customer.customer_term,
			)
			let dayOfWeekDigit = rawInvoiceDueDate.getDay()
			switch (dayOfWeekDigit) {
				case 6:
					return addDays(rawInvoiceDueDate, 2)
				case 0:
					return addDays(rawInvoiceDueDate, 1)
				default:
					return rawInvoiceDueDate
			}
		}
		const isSwedish = ref(true)
		const fetchDates = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					invoiceDate.value = new Date()
					invoiceDueDate.value = getDueDate(invoiceDate.value)
					isSwedish.value = true
					resolve(true)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// ! ---------------------------------------------------------------
		const setDates = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					selectedInvoiceDate.value = invoiceDate.value
					selectedInvoiceDueDate.value = invoiceDueDate.value
					selectedInvoiceLanguage.value = 'se'
					console.log(
						'setDates resolve',
						selectedInvoiceDate.value,
						selectedInvoiceDueDate.value,
						selectedInvoiceLanguage.value,
					)
					resolve('Ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onMounted(() => {
			fetchDates(true)
				.then((data) => {
					setDates(data)
				})
				.then(() => {
					console.log('Dates are set')
					console.log(' sale-invoice date', selectedInvoiceDate.value)
					console.log(' sale-invoice due date', selectedInvoiceDueDate.value)
					console.log(' selectedInvoiceLanguage', selectedInvoiceLanguage.value)
				})
		})
		const {
			stateSalesInvoice,
			selectedInvoiceDate,
			selectedInvoiceDueDate,
			selectedInvoiceLanguage,
		} = useSalesInvoiceState()
		watch(invoiceDate, () => {
			invoiceDueDate.value = getDueDate(invoiceDate.value)
			selectedInvoiceDate.value = invoiceDate.value
		})
		watch(invoiceDueDate, (newValue) => {
			selectedInvoiceDueDate.value = invoiceDueDate.value
			if (newValue.getDay() === 6 || newValue.getDay() === 0) {
				alert('The day you choose is not a weekday')
			}
		})
		watch(isSwedish, (newValue) => {
			console.log(newValue)
			if (isSwedish.value) {
				selectedInvoiceLanguage.value = 'se'
			} else {
				selectedInvoiceLanguage.value = 'en'
			}
		})
		const term = computed(
			() => `${stateSalesInvoice.customer.customer_term}  day(s)`,
		)
		return {
			firstDayOfTheWeek,
			showWeekNumber,
			invoiceDate,
			invoiceDueDate,
			isSwedish,
			locale,
			term,
		}
	},
}
</script>

<style scoped></style>
