<!-- @format -->

<template>
	<div>
		<layout-default-new>
			<sub-navbar page="Sales Invoice" />
			<div>
				<div v-if="isPopupVisible">
					<VPopup :closePopup="onClosePopup">
						<template #header>
							<div>
								{{ formName }}
							</div>
						</template>
						<template #body>
							<div>
								<ViewSelectedDispatch />
							</div>
						</template>
						<template #footer>
							<div>
								{{ formName }}
							</div>
						</template>
					</VPopup>
				</div>
				<section class="hero is-danger margin-bottom-20">
					<div class="hero-body">
						<p class="title">Create invoice from dispatches</p>
						<p class="subtitle">Please select the dispatches to invoice</p>
					</div>
				</section>
				<section class="margin-bottom-20 margin-left-20">
					<p>
						<span class="text-bold">OurCompany:</span>
						&nbsp;{{ ourCompany.toUpperCase() }}
					</p>
					<p>
						<span class="text-bold">Customer:</span>
						&nbsp;{{ selectedCustomer }}
					</p>
					<p>
						<span class="text-bold">Invoice Address:</span>&nbsp;
						{{ invoiceAddress.address_nickname }}
					</p>
					<p>{{ invoiceAddress.line_1 }}&nbsp;{{ invoiceAddress.line_2 }}</p>
					<p>
						{{ invoiceAddress.post_code }}&nbsp;<span class="text-bold">{{
							invoiceAddress.city
						}}</span>
					</p>
					<p>{{ invoiceAddress.state }}&nbsp;{{ invoiceAddress.country }}</p>
				</section>
				<section>
					<invoice-header />
				</section>
			</div>
			<p class="text-bold">Customer's dispatches waiting to invoice</p>
			<div class="margin-bottom-30 margin-top-10">
				<vue-good-table
					id="dispatches"
					:columns="columns"
					:rows="rows"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClick"
					@on-cell-click="onCellClick"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page',
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button
								class="is-small is-success"
								:disabled="isSelectDisabled"
							>
								Select
							</b-button>
						</span>
						<span v-else-if="props.column.field === 'dispatch_total'">
							<p class="text-bold text-purple text-align-right">
								{{ numeral(props.row.dispatch_total / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'preview'">
							<b-button class="is-small is-info">Preview</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
			<p class="text-bold">Selected dispatches to invoice</p>
			<div class="margin-top-10 margin-bottom-30">
				<vue-good-table
					id="selected_dispatches"
					:columns="selectedDispatchesColumns"
					:rows="rowsSelectedDispatches"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClickSelectedDispatches"
					@on-cell-click="onCellClickSelectedDispatches"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page',
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'remove'">
							<b-button
								class="is-small is-success"
								:disabled="isRemoveDisabled"
							>
								Remove
							</b-button>
						</span>
						<span v-else-if="props.column.field === 'dispatch_total'">
							<p class="text-bold text-purple text-align-right">
								{{ numeral(props.row.dispatch_total / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'preview'">
							<b-button class="is-small is-info">Preview</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
			<div
				v-if="applyInvoiceFee.showCheckbox"
				style="
					height: 85px;
					margin-top: -30px;
					margin-bottom: 0px;
					color: white;
					background-color: rgb(78, 92, 123);
				"
			>
				<div
					style="
						margin-left: 20px;
						margin-top: 30px;
						padding-top: 25px;
						font-weight: 900;
					"
				>
					{{ applyInvoiceFee.text }} &nbsp; - &nbsp; {{ feeMessage }}
				</div>
				<div class="margin-left-20 margin-top-15">
					<div class="block">
						<b-radio
							:disabled="disableRadioButton"
							v-model="applyInvoiceFee.bool"
							type="is-success"
							name="name"
							:native-value="boolTrue"
						>
							Apply
						</b-radio>
						<b-radio
							:disabled="disableRadioButton"
							v-model="applyInvoiceFee.bool"
							type="is-danger"
							name="name"
							:native-value="boolFalse"
						>
							DO NOT APPLY
						</b-radio>
					</div>
				</div>
			</div>
			<div style="background-color: rgb(76, 92, 122); padding-bottom: 30px">
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="
						preventButtonDoubleClick(
							onSaveAndView,
							1000,
							disableObject,
							'onSave',
						)
					"
					:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
				>
					Save
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(61, 196, 134); color: white"
					@click="
						preventButtonDoubleClick(
							onSaveAndView,
							1000,
							disableObject,
							'onSaveAndView',
						)
					"
					:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
				>
					Save & View
				</b-button>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					type="is-danger"
					outlined
					@click="onCancel"
				>
					Cancel
				</b-button>
			</div>
			<div class="margin-bottom-30">
				<div v-if="!!isModalVisible">
					<modal-invoice-frame :key="keyModal" processType="add" />
				</div>
			</div>
		</layout-default-new>
	</div>
</template>

<script>
// * Components
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import InvoiceHeader from '@/_srcv2/pages/sale-invoice/add-invoice/InvoiceHeader'
// * Composition APi
import { computed, onMounted, reactive, ref, watch } from '@vue/composition-api'
// * State
import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid.js'
// * import storeAccounting from '@/_srcv2/pages/_pages-store/storeShared.js'
// * GraphQL
import GetOurCompanyFeeStatusQuery from '@/_srcv2/pages/sale-invoice/graphql/GetOurCompanyFeeStatusQuery.graphql'
import GetNewInvoiceNumberMutation from '@/_srcv2/pages/sale-invoice/graphql/GetNewInvoiceNumberMutation.gql'
import AddNewInvoiceMutation from '@/_srcv2/pages/sale-invoice/graphql/AddNewInvoiceMutation.gql'
import AddDispatchToInvoiceMutation from '@/_srcv2/pages/sale-invoice/graphql/AddDispatchToInvoiceMutation.gql'
import SetInvoiceLockedMutation from '@/_srcv2/pages/sale-invoice/graphql/SetInvoiceLockedMutation.gql'
import { useMutation, useQuery } from '@vue/apollo-composable'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore'
import ModalInvoiceFrame from '@/_srcv2/pages/_reports/get-data-and-report/invoice/ModalInvoiceFrame'
import dateformat from 'dateformat'
import Router from '@/router'
import Store from '@/store'
// todo ---------------------------------------------------------------------------------------------
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import ViewSelectedDispatch from '@/_srcv2/pages/sale-invoice/add-invoice/ViewSelectedDispatch.vue'
import useSelectedDispatchViewState from '@/_srcv2/pages/sale-invoice/_shared/useSelectedDispatchViewState.js'
import numeral from 'numeral'

export default {
	name: 'AddInvoice',
	components: {
		SubNavbar,
		InvoiceHeader,
		ModalInvoiceFrame,
		VPopup,
		ViewSelectedDispatch,
	},
	setup() {
		// todo -------------------------------------------------------------------------
		const {
			ourCompany,
			customer,
			invoiceAddress,
			dispatches,
			selectedDispatches,
			selectDispatchToInvoiceColumns,
			selectedDispatchesColumns,
			stateSalesInvoice,
			selectedInvoiceDate,
			selectedInvoiceDueDate,
			selectedInvoiceLanguage,
			invoiceExchangeRate,
			invoiceExchangeUnit,
			invoiceAddressId,
			invoiceType,
			isInvoiceSendByEmail,
		} = useSalesInvoiceState()
		console.log(
			'AddInvoice UseSalesInvoiceState customer.value',
			customer.value,
		)
		// todo --------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult } = useQuery(
			GetOurCompanyFeeStatusQuery,
			() => ({
				company_nickname: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const invoiceFee = reactive({
			isAppliable: false,
			invoiceFeeAmount: 0,
			invoiceFeeVat: 0,
		})
		onResult((result) => {
			const obj = result.data.companies_by_pk
			invoiceFee.isAppliable = obj.is_invoice_fee_appliable
			invoiceFee.invoiceFeeAmount = obj.invoice_fee_to_apply
			invoiceFee.invoiceFeeVat = obj.invoice_fee_vat
			console.log('invoiceFee', invoiceFee)
			console.log('isInvoiceSendByEmail', isInvoiceSendByEmail.value)
		})
		// todo ----------------------------------------------------------------------
		const boolTrue = ref(true)
		const boolFalse = ref(false)
		// todo ----------------------------------------------------------------------
		const applyInvoiceFee = reactive({
			bool: true,
			text: 'Faktura Avgift is applied',
			showCheckbox: false,
		})

		const { getUUID } = useGetUuid()
		const disableRadioButton = ref()
		const getApplyInvoiceValue = () => {
			console.log('getApplyInvoiceValue is fired')
			if (invoiceFee.isAppliable === false) {
				disableRadioButton.value = true
				applyInvoiceFee.bool = false
				applyInvoiceFee.text = 'Faktura Avgift is NOT APPLIABLE'
				mutationVariablesAddNewInvoice.is_fee_addable = false
			} else if (invoiceType.value !== 'Inland') {
				disableRadioButton.value = true
				applyInvoiceFee.bool = false
				applyInvoiceFee.text =
					'Faktura Avgift is NOT APPLIABLE (Invoice to abroad)'
				mutationVariablesAddNewInvoice.is_fee_addable = false
			} else if (isInvoiceSendByEmail.value === true) {
				applyInvoiceFee.bool = false
				applyInvoiceFee.text = 'Faktura Avgift is NOT applied (Email Invoice)'
				mutationVariablesAddNewInvoice.is_fee_addable = false
			} else {
				disableRadioButton.value = false
				applyInvoiceFee.bool = true
				applyInvoiceFee.text = 'Faktura Avgift is applied'
				mutationVariablesAddNewInvoice.is_fee_addable = true
			}
		}
		const feeMessage = computed(() => {
			if (applyInvoiceFee.bool === false) {
				return ''
			} else if (applyInvoiceFee.bool === true) {
				return `(Fee: ${numeral(invoiceFee.invoiceFeeAmount / 100).format(
					'0,0.00',
				)} kr. moms: ${numeral(invoiceFee.invoiceFeeVat / 100).format(
					'0,0.00',
				)} kr.)`
			} else {
				return 'Please select whether the will be applied or not'
			}
		})
		const documentTransactionId = ref('')
		onMounted(() => {
			reportProcessType.value = 'add'
			documentTransactionId.value = getUUID()
			getApplyInvoiceValue()
			applyInvoiceFee.showCheckbox = false
			setTimeout(() => {
				getApplyInvoiceValue()
				applyInvoiceFee.showCheckbox = true
			}, 1000)
		})
		// todo ----------------------------------------------------------------------
		const i = ref(0)
		watch(
			() => applyInvoiceFee.bool,
			(newValue) => {
				i.value += 1
				console.log(i.value, `New Value is: ${newValue}`)
				if (i.value > 2) {
					if (applyInvoiceFee.bool === true) {
						applyInvoiceFee.text = 'Faktura Avgift is applied (User Choice)'
						mutationVariablesAddNewInvoice.is_fee_addable = true
					} else if (applyInvoiceFee.bool === false) {
						applyInvoiceFee.text = 'Faktura Avgift is NOT applied (User Choice)'
						mutationVariablesAddNewInvoice.is_fee_addable = false
					} else {
						alert('Will Faktura Avgift be applied or not, please choose')
					}
				}
			},
		)
		const onCancel = () => {
			Router.push('/sale-invoice')
		}
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.dispatch_number !== value
			})
		}
		// ? ----------------------------------------------------------------------
		const {
			selectedDispatchNumber,
			selectedDispatchDate,
			selectedDispatchCustomer,
			rowsSelectedDispatch,
			selectedDispatchViewState,
		} = useSelectedDispatchViewState()
		// ? ----------------------------------------------------------------------
		// * Eligible dispatches table
		const rows = computed(() => dispatches.value)
		const isSelectDisabled = ref(false)
		const saveDispatchHeaders = (params) => {
			console.log('========> params.row', params.row)
			selectedDispatchCustomer.value =
				customer.value.customer_id +
				' - ' +
				customer.value.customer_title +
				' - ' +
				customer.value.customer_nickname
			selectedDispatchDate.value = params.row.dispatch_date
			selectedDispatchNumber.value = params.row.dispatch_number
			console.log(
				'******** selectedDispatchViewState',
				selectedDispatchViewState,
			)
			rowsSelectedDispatch.value = params.row?._goods_transactions
		}
		const onSelect = (params) => {
			if (params.column.field === 'select') {
				if (params.column.field === 'select') {
					selectedDispatches.value.push(params.row)
					dispatches.value = arrayRemove(
						dispatches.value,
						params.row.dispatch_number,
					)
				}
				isSelectDisabled.value = true
				setTimeout(() => {
					isSelectDisabled.value = false
				}, 1000)
			} else if (params.column.field === 'preview') {
				saveDispatchHeaders(params)
				isPopupVisible.value = true
			}
		}
		// todo ---------------------------------------------------------------------------
		const onCellClick = (params) => {
			onSelect(params)
		}
		const onRowDoubleClick = (params) => {
			onSelect(params)
		}
		// const columns = computed(() => selectDispatchToInvoiceColumns.value)
		const selectedCustomer = computed(
			() =>
				`${customer.value.customer_id} - ${customer.value.customer_title} - ${customer.value.customer_nickname}`,
		)
		// todo ------------------------------------------------------------------------------
		// * Selected Dispatches Table
		const columns = computed(() => selectDispatchToInvoiceColumns.value)
		const rowsSelectedDispatches = computed(() => selectedDispatches.value)
		const isRemoveDisabled = ref(false)
		const onRemove = (params) => {
			if (params.column.field === 'remove') {
				dispatches.value.push(params.row)
				selectedDispatches.value = arrayRemove(
					selectedDispatches.value,
					params.row.dispatch_number,
				)
				isRemoveDisabled.value = true
				setTimeout(() => {
					isRemoveDisabled.value = false
				}, 1000)
			} else if (params.column.field === 'preview') {
				saveDispatchHeaders(params)
				isPopupVisible.value = true
			}
		}
		const onCellClickSelectedDispatches = (params) => {
			onRemove(params)
		}
		const onRowDoubleClickSelectedDispatches = (params) => {
			onRemove(params)
		}
		// todo -----------------------------------------------------------------------------------------
		const disableButtons = computed(() => selectedDispatches.value.length === 0)
		// todo -----------------------------------------------------------------------------------------------------------
		// * GetNewInvoiceNumberMutation
		const {
			mutate: mutateGetNewInvoiceNumber,
			// onDone: onDoneGetNewInvoiceNumber,
		} = useMutation(GetNewInvoiceNumberMutation, () => ({
			variables: {
				company_id: ourCompany.value,
			},
		}))
		// onDoneGetNewInvoiceNumber((result) => console.log('result', result))
		// * AddNewInvoiceMutation
		const mutationVariablesAddNewInvoice = reactive({
			document_transaction_id: computed(() => documentTransactionId.value),
			company_id: ourCompany.value,
			customer_id: customer.value.customer_id,
			invoice_number: '',
			invoice_date: computed(() =>
				dateformat(selectedInvoiceDate.value, 'yyyy-mm-dd'),
			),
			invoice_due_date: computed(() =>
				dateformat(selectedInvoiceDueDate.value, 'yyyy-mm-dd'),
			),
			invoice_type: invoiceType.value,
			invoice_language: computed(() => selectedInvoiceLanguage.value),
			invoice_exchange_rate: invoiceExchangeRate.value,
			invoice_exchange_unit: invoiceExchangeUnit.value,
			invoice_address_id: invoiceAddressId.value,
			transaction_type: 'sales invoice ',
			is_fee_addable: '',
			paper_invoice_fee: computed(() => invoiceFee.invoiceFeeAmount),
			paper_invoice_fee_vat: computed(() => invoiceFee.invoiceFeeVat),
			paper_invoice_fee_vat_percent: 25,
		})
		const {
			mutate: mutateAddNewInvoiceMutation,
			// onDone: onDoneAddNewInvoiceMutation,
		} = useMutation(AddNewInvoiceMutation, () => ({
			variables: mutationVariablesAddNewInvoice,
		}))
		// onDoneAddNewInvoiceMutation(() => console.log('a'))
		// * AddDispatchToInvoiceMutation
		const mutationVariablesDispatchToInvoice = reactive({
			dispatch_number: '',
			invoice_number: '',
		})
		const {
			mutate: mutateAddDispatchToInvoice,
			// onDone: onDoneAddDispatchToInvoice,
		} = useMutation(AddDispatchToInvoiceMutation, () => ({
			variables: mutationVariablesDispatchToInvoice,
		}))
		// * SetInvoiceLockedMutation
		const mutationVariablesSetInvoiceLocked = reactive({
			our_company: ourCompany.value,
			invoice_number: '',
		})
		const {
			mutate: mutateSetInvoiceLocked,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(SetInvoiceLockedMutation, () => ({
			variables: mutationVariablesSetInvoiceLocked,
		}))
		const setNewInvoiceNumber = (result) => {
			console.log(
				'setNewInvoiceNumber is fired',
				result.data.update_companies.returning[0].invoice_number,
			)
			return new Promise((resolve, reject) => {
				if (result !== 0) {
					const initial = result.data.update_companies.returning[0].letter_code
					mutationVariablesDispatchToInvoice.invoice_number = `${initial} ${result.data.update_companies.returning[0].invoice_number}`
					mutationVariablesSetInvoiceLocked.invoice_number = `${initial} ${result.data.update_companies.returning[0].invoice_number}`
					mutationVariablesAddNewInvoice.invoice_number = `${initial} ${result.data.update_companies.returning[0].invoice_number}`
					resolve(mutationVariablesDispatchToInvoice.invoice_number)
				} else {
					const reason = new Error('New Invoice Number could not be created')
					reject(reason)
				}
			})
		}
		const insertInvoiceHeaders = (data) => {
			console.log('insertInvoiceHeaders is fired')
			return new Promise((resolve, reject) => {
				console.log(
					'insertInvoiceHeaders is fired mutationVariablesAddNewInvoice',
					mutationVariablesAddNewInvoice,
				)
				let result = ''
				if (data !== null || undefined) {
					mutateAddNewInvoiceMutation()
					resolve(result)
				} else {
					const reason = new Error(
						'Invoice headers could not be added to database',
					)
					reject(reason)
				}
			})
		}
		const selectedDispatchNumbers = computed(() =>
			selectedDispatches.value.map((item) => item.dispatch_number),
		)
		const updateGoodsTransactions = (data) => {
			console.log('updateGoodsTransactions is fired')
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					console.log('****selectedDispatchNumbers', selectedDispatchNumbers)
					for (let item in selectedDispatchNumbers.value) {
						mutationVariablesDispatchToInvoice.dispatch_number =
							selectedDispatchNumbers.value[item]
						console.log('item: ', selectedDispatchNumbers.value[item])
						mutateAddDispatchToInvoice()
					}
					// mutateAddDispatchToInvoice()
					resolve('ok')
				} else {
					const reason = new Error(
						'Dispatches could not be added to sale-invoice',
					)
					reject(reason)
				}
			})
		}
		const createTheInvoice = (method) => {
			mutateGetNewInvoiceNumber()
				.then((result) => setNewInvoiceNumber(result))
				.then((data) => {
					insertInvoiceHeaders(data)
					console.log('**** insertInvoiceHeaders data', data)
				})
				.then((data) => updateGoodsTransactions(data))
				.then(() => mutateSetInvoiceLocked())
				.then(() => {
					if (method === 'onSave')
						Router.push({ name: 'ViewDispatchesToInvoice' })
					else getPdfReport()
				})
			console.log(method)
		}
		// todo New Invoice is added to db above
		// ** -----------------------------------------------------------------------------------
		const preventButtonDoubleClick = (fn, delay, obj, method) => {
			fn(method)
			obj.isSaveAndViewDisabled = true
			setTimeout(() => (obj.isSaveAndViewDisabled = false), delay)
		}
		const disableObject = reactive({
			isSaveAndViewDisabled: false,
		})
		const onSaveAndView = (method) => createTheInvoice(method)
		// ** ---------------------------------------------------------------------------------------
		const getPdfReport = () => {
			setVariables()
				.then(() => {
					isModalVisible.value = true
					keyModal.value += 1
				})
				.then(() =>
					console.log('reportDocumentNumber: ', reportDocumentNumber.value),
				)
		}
		// ** ---------------------------------------------------------------------------------------------
		const {
			reportDocumentType,
			reportProcessType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// ** -------------------------------------------------------------------------------------------------
		const keyModal = ref(0)
		const setVariables = () => {
			let condition = true
			return new Promise((resolve, reject) => {
				if (condition) {
					reportDocumentType.value = 'sale-invoice'
					reportDocumentNumber.value =
						mutationVariablesAddNewInvoice.invoice_number
					reportDocumentOurCompany.value = ourCompany.value
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = `${customer.value.customer_id} - ${customer.value.customer_title} - ${customer.value.customer_nickname}`
					// reportCustomer.value = `${customerId.value} - ${customerTitle.value} - ${customerNickname.value} - ${customerOrgNum.value}`
					reportDocumentDate.value = mutationVariablesAddNewInvoice.invoice_date
					reportDocumentStatus.value = 'locked'
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// todo -------------------------------------------------------------------------
		const isPopupVisible = ref(false)
		const onClosePopup = () => {
			isPopupVisible.value = false
		}
		// todo -----------------------------------------------------------
		const formName = ref('Preview Dispatch Details')
		// ** -------------------------------------------------------------
		return {
			boolTrue,
			boolFalse,
			disableRadioButton,
			// -----------------------
			applyInvoiceFee,
			feeMessage,
			invoiceType,
			onCancel,
			isModalVisible,
			formName,
			onClosePopup,
			isPopupVisible,
			columns,
			selectedDispatchesColumns,
			rows,
			onCellClick,
			onRowDoubleClick,
			rowsSelectedDispatches,
			onCellClickSelectedDispatches,
			onRowDoubleClickSelectedDispatches,
			ourCompany,
			// selectedCustomer,
			invoiceAddress,
			dispatches,
			stateSalesInvoice,
			disableButtons,
			onSaveAndView,
			preventButtonDoubleClick,
			disableObject,
			numeral,
			isRemoveDisabled,
			isSelectDisabled,
			keyModal,
			selectedCustomer,
			// ------------------------
		}
	},
}
</script>

<style scoped></style>
